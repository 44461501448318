/*
Theme Name:     Untitled Era \ Shopify Base
Development:	Untitled Era LLC
Version:        1.2
*/



/*--account--*/

hr.account-hr {
  width: 100% !important;
  max-width: 100% !important;
  margin: 30px 0;
}

.errors ul li {
  display: block;
  color: #A30000;
  margin-bottom: 20px;
}

.line-sep-before {
  position: relative;
}

.line-sep-before:before {
  content: ' ';
  position: absolute;
  left: 0;
  top: 0;
  background: #D9DEE1;
  width: 1px;
  height: 100%;  
}

.line-sep-after {
  position: relative;
}

.line-sep-after:after {
  content: ' ';
  position: absolute;
  right: 0;
  top: 0;
  background: #D9DEE1;
  width: 1px;
  height: 100%;  
}

.order-link a {
  color: #219592;
  text-decoration: none;
}

.responsive-table th, .responsive-table td {
  padding: 12px 0;
  border: none;
}

.responsive-table th, .responsive-table td {
  border-bottom: 1px solid #D9DEE1;
}

.customer-product-img {
  height: 100px;
  display: block;
}

.curr-addresses .customer-curr-address {
  padding: 15px 0 0;
  border-top: 1px solid #D9DEE1;
  margin-bottom: 15px;
}

.curr-addresses .customer-curr-address:last-of-type {
    border-bottom: 1px solid #D9DEE1;
    padding-bottom: 15px;
}


/*--MODAL--*/
.close-popup {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    z-index: 999;
    width: 20px;
    height: 20px;
}

.close-popup svg {
  width: 100%;
  height: auto;
}

.contact-form-success {
  padding: 0px 0;
}

.popup-modal {
    overflow-y: scroll;
    max-height: calc(100% - 100px);
    height: auto;
    /*top: 50%;*/
    top: 50px;
    position: fixed;
    z-index: 999999;
    text-align: left;
    display: none;
    left: 20%;
    width: 60%;
}

.error-message, .errors {
    color: #d60000;
}

#quote-form .button.col-12 {
    width: 100%;
    max-width: 100%;
    padding-top: 10px;
    padding-bottom: 9px;
    position: relative;
    top: 2px;
}


.popup-modal-overlay {
  position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.75);
    z-index: 99999;
    /* display: none; */  
}

.contact-form {
  /*padding: 4px 0;*/
}

.modal .modal-inner {
  max-width: 900px;
  padding: 60px 50px 30px;
}

.contact-form .contact-form__email {
	padding-left: 0 !important;
}

.product-item__sold-out {
  margin-top: 10px;
}

@media all and (max-width: 1100px) {
	.popup-modal {
		width: 80%;
		left: 10%;
	}
}


@media all and (max-width: 480px) {
  /*--ACCOUNT--*/
  tfoot>.responsive-table__row:first-child {
    position: relative;
    margin-top: 0px;
    padding-top: 0px;
  }

  .template-customers-order tfoot {
    display: block;
    margin-top: 0px;
    font-size: 1em !important;
  }

  .template-customers-order tfoot tr {
  	padding: 10px 0;
  }

  .template-customers-order tfoot tr:first-of-type {
    /*padding-top: 10px;*/
    border-top: 1px solid #E1E5E8;
    border-top: none !important;
  }  

  .template-customers-order tfoot tr {
    display: block;
    position: relative;
    float: none;
  }

  .template-customers-order tfoot tr td {
    display: inline-block;
    width: auto;
    position: relative;
    float: none;
    /* margin-left: -4px; */
    padding: 0;
    margin-right: 9px;
    border: none;
  }

  .template-customers-order tfoot tr td:first-of-type {
  	font-weight: bold;
  }

  .template-customers-order table.responsive-table tbody tr td:last-of-type {
    border-bottom: none !important;
  }

  .template-customers-order table.responsive-table tbody tr td.order-td {
    text-align: left !important;
    padding: 0;
    border-bottom: none;
    margin-bottom: 20px;
  }

  .responsive-table th, .responsive-table td {
    padding: 8px 0;
    border: none;
    border-bottom: 1px solid #D9DEE1;
  }

  .template-customers-order table.responsive-table td:before {
    display: none !important;
  } 

  table.responsive-table tr {
    border-top: 2px solid #000;
  }

  table.responsive-table tr:last-of-type {
    border-bottom: 2px solid #000;
  }

  .responsive-table tr td:last-of-type {
    border-bottom: none;
  }
}